// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-businesses-js": () => import("./../src/pages/businesses.js" /* webpackChunkName: "component---src-pages-businesses-js" */),
  "component---src-pages-disclosure-js": () => import("./../src/pages/disclosure.js" /* webpackChunkName: "component---src-pages-disclosure-js" */),
  "component---src-pages-ea-js": () => import("./../src/pages/ea.js" /* webpackChunkName: "component---src-pages-ea-js" */),
  "component---src-pages-employees-js": () => import("./../src/pages/employees.js" /* webpackChunkName: "component---src-pages-employees-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lyft-js": () => import("./../src/pages/lyft.js" /* webpackChunkName: "component---src-pages-lyft-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-providers-js": () => import("./../src/pages/providers.js" /* webpackChunkName: "component---src-pages-providers-js" */),
  "component---src-pages-research-js": () => import("./../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-top-companies-that-support-employees-mental-well-being-js": () => import("./../src/pages/top-companies-that-support-employees-mental-well-being.js" /* webpackChunkName: "component---src-pages-top-companies-that-support-employees-mental-well-being-js" */)
}

